<template>
    <button :style="buttonStyle" :class="classes" class="flex items-center justify-center gap-1"
        @click.prevent.stop="handleClick" :disabled="disabled" :data-cy="dataCy">
        <slot name="leadingSlot"></slot>
        <spinner v-if="loading" />
        {{ buttonLabel }}
        <slot> </slot>
    </button>
</template>
  
<script>
export default {
    props: {
        variant: {
            type: String,
            default: "primary",
            validator: function (value) {
                return ["primary", "secondary", "with_border"].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonLabel: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        dataCy: {
            type: String,
            default: "",
        },
        dataClass: {
            type: String,
            default: "",
        },
        buttonStyle: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    methods: {
        handleClick: function () {
            if (!this.disabled) {
                this.$emit("submit");
            }
        }
    },
    computed: {
        classes: function () {
            return `button ${this.variant} ${this.disabled ? "disabled" : ""} ${this.dataClass}`;
        }
    }
}
</script>
  
<style scoped>
.button {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}

.primary {
    background-color: #dd5928;
    color: #fff;
}

.primary:hover {
    background: #b5461d;
}

.secondary {
    color: #dd5928;
}

.with_border {
    border: 1px solid #eff0f3;
    color: #8f95b2;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
