<template>
  <div class="access_container">
    <template>
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        :color="isError ? 'error' : 'success'"
        center
        v-model="snackBar"
        outlined
        text
        elevation="1"
      >
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isloading"> 
      <spinner></spinner>
    </div>
    <div>
      <bread-crumbs :breadcrumbs="items"></bread-crumbs>
      <access-level-component></access-level-component>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../../components/payrolls/BreadCrumbs.vue";
import AccessLevelComponent from "../../components/layouts/AccessLevelComponent.vue";

export default {
  components: {
    BreadCrumbs,
    AccessLevelComponent,
  },

  data: () => ({
    items: [
      { label: "Home", to: "/employee" },
      { label: "Access Level", to: "/access-level" },
    ],
    selectedName: "",
    isloading: false,
  }),
  computed: {
    isError() {
      return this.$store.state.access.isError;
    },
    snackBar() {
      return this.$store.state.access.snackBar;
    },
    snackBarText() {
      return this.$store.state.access.snackBarText;
    }
  },
};
</script>

<style scoped>
.info_circle {
  line-height: 2rem;
  padding-right: 1rem;
}

.search {
  width: fit-content;
}

.onboarding {
  background: #ffffff;
  box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.24);
  border-radius: 8px;
  width: 400px;
}

label {
  margin-top: 10px;
  margin-left: 15px;
}
</style>
